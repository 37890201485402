export default class Model {
    static get() {
        return {
            mapping_nodename_with_their_ways: {
                '1336 - Coop. production de thé '               : ['0,2,5'],
                '13ticules'                                     : ['1,1,0,2,0,0'],
                'A2C-AutonomieDeClasse'                         : ['1,1,2,2,0'],
                'Acceuil Migrant·s·es'                          : ['1,1,3,0'],
                'accueil de jour Sirakadjan'                    : ['0,4,1,3'],
                Addictions                                      : ['1,1,0,4', '1,1,2,4,2,4'],
                'Adèle'                                         : ['1,1,7,5,0,3', '2,9,0,3'],
                Administratif                                   : ['1,1,3,1'],
                Agendas                                         : ['2,0'],
                'AG InterPro'                                   : ['0,4,3,0'],
                'AG-InterPro'                                   : ['1,0,2,0,0'],
                'AG précaires'                                  : ['0,4,4,2', '1,0,2,1,0,2'],
                'AIDES à Marseille'                             : ['0,0,5'],
                AiLSi                                           : ['1,0,6,1'],
                'Aïolibre'                                      : ['0,4,2,5'],
                Alcazar                                         : ['0,3,0'],
                'Al Manba'                                      : ['0,4,5', '1,1,3,0,1'],
                Alternatiba                                     : ['0,4,2,0'],
                'Ami·es'                                        : ['2,2,0,0'],
                'antifa social club'                            : ['0,4,1,10'],
                'Antifa Social Club'                            : ['1,0,5,4'],
                'ANV COP-21m'                                   : ['0,4,2,0,0'],
                'Approches Cultures & Territoires'              : ['1,1,4,6', '2,0,5'],
                Aquaponie                                       : ['2,13,0,0,2'],
                Artisanat                                       : ['1,1,2,1'],
                Arts                                            : ['1,1,2,0'],
                'Arts Martiaux/Box'                             : ['1,1,5,2'],
                'Assemblées Antifa'                             : ['1,0,5,5'],
                Asso                                            : ['0,4,4,4', '1,0,2,1,0,4'],
                'Association Cuve'                              : ['0,2,2', '1,1,7,0,1', '1,1,7,4,2'],
                'Association Kokopelli'                         : ['2,2,0,0,1'],
                Associations                                    : ['0,0'],
                'Atelier Couture (DAR)'                         : ['1,1,2,1,0,0'],
                'Atelier de Couture'                            : ['0,4,1,5'],
                'Atelier du Mouvement (danse)'                  : ['0,4,1,1'],
                'Atelier Palmas(€)'                             : ['1,1,2,0,1,2'],
                'Atelier Recherche Formation'                   : ['0,4,11,1'],
                Ateliers                                        : ['1,1,6,3', '2,10'],
                'Ateliers Nebula'                               : ['1,1,2,4,0'],
                'Ateliers SPAAM'                                : ['1,1,2,4,1'],
                'Ateliers Vélo'                                 : ['2,10,0'],
                'Atelier Théâtre'                               : ['0,4,1,2'],
                "AUP - Demandeurs d'asile"                      : ['1,1,3,0,5', '1,1,4,7'],
                'Autodéfense'                                   : ['1,1,5'],
                Autonomes                                       : ['1,0,2,0'],
                Autonomie                                       : ['2,13,0'],
                Autres                                          : ['1,1,2,6'],
                'Autres Regards'                                : ['0,0,4', '1,1,0,3,7', '1,1,3,3,9'],
                AVAD                                            : ['1,1,1,2', '1,1,3,3,11'],
                'Banques Alternatives'                          : ['1,0,3,0'],
                'Bar à Vrac'                                    : ['1,1,7,5,0,0', '2,9,0,0'],
                'Bars | Cafés | Salles'                         : ['0,1'],
                'Bataille Médiatique'                           : ['1,0,0'],
                'Bibliothèque Nulle part Ailleurs'              : ['0,4,1,6'],
                'Bien Être'                                     : ['1,1,2,4,2,0'],
                Biodigesteur                                    : ['2,13,0,0,3'],
                Blocage                                         : ['1,0,1'],
                Blogs                                           : ['1,0,0,2'],
                Bouffe                                          : ['1,1,7'],
                'Boulangerie-Café Pain Salvator'                : ['1,1,7,5,2,2', '2,9,2,2'],
                Boulangeries                                    : ['1,1,7,5,2', '2,9,2'],
                'Bourse du Travail'                             : ['0,4,7'],
                Box                                             : ['0,4,13,0'],
                'Box Morozoff'                                  : ['1,1,5,2,1'],
                'Brigade Antifa'                                : ['1,0,5,2'],
                'BTP autonome'                                  : ['0,4,4,1', '1,0,2,1,0,1'],
                'Bus 31/32'                                     : ['1,1,0,4,1'],
                "Café l'Ecomotive"                              : ['1,1,7,5,1,1', '2,9,1,1'],
                'Café Villageois (Lauris)'                      : ['0,5,0'],
                'Calculs Mutuelle'                              : ['2,12,0'],
                'Cantine Chaubouillante'                        : ['0,4,1,8'],
                'Cantines Solidaires'                           : ['1,1,7,1'],
                'Cantine The Noble Kitchen'                     : ['0,4,1,7'],
                'Carte Autogéré Rhizome'                        : ['2,1,1'],
                'Carte des Luttes - Reporterre'                 : ['2,1,3'],
                Cartes                                          : ['2,1'],
                'Carto-Marseille'                               : ['2,1,5'],
                'Casa Consolat'                                 : ['1,1,7,1,4', '1,1,7,2,1', '1,1,7,5,1,2', '2,9,1,2'],
                'Catégories'                                    : ['1'],
                'Causeries Mensueles'                           : ['0,4,11,0'],
                'Centre LGBTQIA+'                               : ['0,4,8', '1,1,0,3,9', '1,1,2,3,2', '1,1,3,3,6', '2,0,4'],
                'Cercle de Nageurs en Eaux Troubles'            : ['0,4,0,2'],
                'CGT chômeur précaires'                         : ['0,4,7,0'],
                'CGT spectacle'                                 : ['0,4,7,1'],
                'Chaines Youtube'                               : ['1,0,0,1'],
                CHO3                                            : ['1,1,4,1', '1,1,7,1,0'],
                'Chomage | Precarité'                           : ['1,1,3,4'],
                'Chomeurs Précaires 13'                         : ['1,1,3,4,1'],
                Chomheureuses                                   : ['1,1,3,4,0'],
                CIMK                                            : ['1,0,10,0'],
                'Ciné Bar (ClubCoop)'                           : ['0,1,0'],
                'Cinéma'                                        : ['1,1,2,0,2'],
                CIRA                                            : ['0,3,11', '0,4,11'],
                'Circuit Court'                                 : ['1,1,7,5', '2,9'],
                "Cité de l'Agriculture"                         : ['0,0,1', '0,3,1', '1,1,4,0'],
                'Cité des Associations'                         : ['0,0,0'],
                Clownozoff                                      : ['0,4,13,1'],
                'CLUB PARENTHESE'                               : ['1,1,0,3,4,1', '1,1,3,3,2,1'],
                CNT13                                           : ['0,4,3,1', '1,0,2,1,1'],
                COFOR                                           : ['1,1,0,3,1', '1,1,3,3,1'],
                'Collectif Anti-Gentrification'                 : ['1,0,7,0'],
                'Collectif Vélos en Ville'                      : ['2,10,0,1'],
                'Collectif WD-40'                               : ['1,1,1,0'],
                'Colletif Antifa'                               : ['1,0,5,3'],
                'Comité Local 13'                               : ['1,0,1,1,0'],
                'CoMob-StCharles'                               : ['1,0,2,0,2'],
                Compostage                                      : ['2,13,0,2'],
                'Confédération Paysanne PACA'                   : ['1,0,2,1,3'],
                Contraception                                   : ['1,1,0,2,0'],
                'Cours FLE'                                     : ['0,4,5,1', '1,1,3,0,1,1'],
                Couture                                         : ['1,1,2,1,0'],
                CQFD                                            : ['0,4,0,7', '1,0,0,0,0', '2,2,4', '2,5,4'],
                Crefada                                         : ['0,0,9', '1,1,2,6,0'],
                Cryptomonnaies                                  : ['1,0,3,2'],
                DATA                                            : ['0,1,4', '0,3,12', '2,3,6'],
                'Démosphère'                                    : ['2,0,7'],
                'De Saint-Alban à La Borde - France Culture'    : ['2,4,1,0'],
                DICADD                                          : ['2,1,6'],
                'Dispositifs Travail Singularité'               : ['1,2,0'],
                Droit                                           : ['1,1,2,4,2,3'],
                'Écoute Active'                                 : ['1,1,2,4,1,0'],
                'Éducation | Formation'                         : ['1,1,2'],
                'El Aché de Cuba'                               : ['0,1,2'],
                'Énergie'                                       : ['2,13,0,1'],
                'En passant par les calanques'                  : ['0,0,2,3'],
                'Entreprises-Autog.'                            : ['1,1,7,4'],
                'Entreprises Autogérés'                         : ['0,2'],
                'Épiceries'                                     : ['1,1,7,5,0', '2,9,0'],
                'Equipe SIDIIS'                                 : ['1,1,0,3,12'],
                ' Face à la Gentrification'                     : ['1,0,7'],
                'Face à la Psychiatrie'                         : ['1,0,6'],
                'Face à la Surveillance'                        : ['1,0,8'],
                'Face au Fascisme'                              : ['1,0,5'],
                'Face au Néo-colonialisme'                      : ['1,0,4'],
                'Face au système Financier'                     : ['1,0,3'],
                'Face aux lois/politiques Gouv.'                : ['1,0,2'],
                'F. au Système Alimentaire'                     : ['1,0,9'],
                "Ferme de l'Etoile"                             : ['0,7,6'],
                "Ferme du Roy d'Espagne"                        : ['0,7,4'],
                'Ferme Pastière (Meryrargues)'                  : ['0,7,5'],
                Fermes                                          : ['0,7'],
                Festivals                                       : ['2,11'],
                'Filature de Chantemerle (Hautes-Alpes)'        : ['0,6,1,3', '0,7,0,3'],
                'Folie et Politique -Barge'                     : ['2,6,1'],
                'Fondation ERIE'                                : ['1,0,6,0'],
                FransGenre                                      : ['2,1,7'],
                Friperies                                       : ['1,1,7,5,5', '2,9,5'],
                "Frip'Insertion - Capelette "                   : ['1,1,7,5,5,1', '2,9,5,1'],
                "Frip'Insertion - Libération"                   : ['1,1,7,5,5,0', '2,9,5,0'],
                GEMs                                            : ['1,1,0,3,4', '1,1,3,3,2'],
                'Genre de Lutte'                                : ['0,4,14,0'],
                'Genre & Sexualités'                            : ['1,1,2,3'],
                'Gestion Déchets'                               : ['1,1,7,3'],
                'Gestion de Crises'                             : ['1,1,2,4,0,0'],
                'G.L.A.M'                                       : ['0,4,8,0', '1,1,3,0,4'],
                'Grange neuve (Forcarlquier)'                   : ['0,6,1,0', '0,7,0,0'],
                'Graph Maker'                                   : ['2,12,1,0,1'],
                'Gratuit pour les urgences administratives'     : ['0,4,10,0'],
                'Groupe Anti-gentrification'                    : ['0,4,0,5'],
                'GSN Géstion de Conflits'                       : ['0,8,0,0'],
                Habicoop                                        : ['2,1,12', '2,8,8'],
                'Hameaux Légers'                                : ['2,1,13'],
                'H.A.S'                                         : ['1,1,3,3,4'],
                'House of Pain'                                 : ['1,1,7,5,2,3', '2,9,2,3'],
                Hypnose                                         : ['1,1,2,4,1,1'],
                'IMAJSanté'                                     : ['1,1,0,3,6', '1,1,3,3,3'],
                'Imprimerie Partagée'                           : ['0,4,10'],
                InfoKiosques                                    : ['2,6'],
                'Infra-personnel'                               : ['1,2'],
                Internationnalisme                              : ['1,0,10'],
                'Jam Poèsie·Musique'                            : ['0,1,1,0'],
                'Journal Ventilo'                               : ['2,0,1'],
                'Journaux Indépendants'                         : ['1,0,0,0'],
                Juridique                                       : ['2,7,1'],
                JUST                                            : ['0,0,2', '1,1,0,3,2', '1,1,4,3'],
                'La Base'                                       : ['0,4,2'],
                'La Bastide à Fruits'                           : ['0,7,8'],
                'Laboratoires de vie'                           : ['0,6'],
                'La Cabrery (Luberon)'                          : ['0,6,1,2', '0,7,0,2'],
                'La Caillasse (Cucuron)'                        : ['0,7,1'],
                'La DAR'                                        : ['0,3,4', '0,4,1'],
                'La Déviation'                                  : ['0,4,15'],
                'La ferme Capri'                                : ['0,7,2', '1,1,7,0,3'],
                'La Ĝ (June)'                                   : ['1,0,3,2,0'],
                'La lutte enchantée'                            : ['1,1,2,0,1,1'],
                'La Marmite Joyeuse'                            : ['1,1,7,1,2', '1,1,7,5,1,4', '2,9,1,4'],
                'La Merveilleuse'                               : ['0,1,1'],
                'La Nebula'                                     : ['0,4,6'],
                'La NEF'                                        : ['1,0,3,0,0'],
                Langues                                         : ['1,1,3,2'],
                'La plaine fraicheur'                           : ['1,1,7,5,0,2', '2,9,0,2'],
                "L'Après M"                                     : ['0,2,0', '1,1,7,4,0'],
                'La Roue (monnaie)'                             : ['0,4,2,6', '1,0,3,1,0', '1,1,4,5'],
                'La Tarantula'                                  : ['0,8,1'],
                'La terre en Thiers'                            : ['1,0,0,0,2', '2,5,5'],
                'Le Bar à Pain'                                 : ['1,1,7,5,2,0', '2,9,2,0'],
                'Le Café des Chômheureuses'                     : ['0,4,1,0'],
                'LE CHÂTEAU EN SANTÉ'                           : ['0,11,1', '1,1,0,3,11', '1,1,3,3,10'],
                'Le Coeur sur la table'                         : ['2,4,0,2'],
                "L'école des vivants"                           : ['0,6,0'],
                'Le Collet des Comtes'                          : ['0,7,3'],
                'Legal Team 13'                                 : ['1,1,5,0'],
                'Le Grain de Sable'                             : ['1,1,7,5,1,3', '2,9,1,3'],
                'Le Marché Rouge'                               : ['1,1,7,2,2', '1,1,7,5,3,0', '2,8,5', '2,9,3,0'],
                "L'Embobineuse"                                 : ['0,1,3'],
                'Le Morozoff'                                   : ['0,4,13'],
                'Le moteur stirling'                            : ['2,13,0,0,0'],
                "L'Empaille (Mouret (12))"                      : ['2,2,0,0,0'],
                "L'Épicerie Paysanne"                           : ['0,2,1', '1,1,7,4,1', '1,1,7,5,0,1', '2,9,0,1'],
                'Le Plan de A à Z'                              : ['0,2,3', '1,1,7,4,3'],
                'Les 8 Pillards'                                : ['0,4,9'],
                'Les Cahiers A2C'                               : ['2,5,2'],
                'Les Champi. de Marseille'                      : ['1,1,7,0,0'],
                'Les Champignons de Marseille (?)'              : ['0,2,4'],
                'Les Champignons de Marseille'                  : ['1,1,7,4,4'],
                'Les Couilles sur la table'                     : ['2,4,0,1'],
                'Les Écotables'                                 : ['2,1,15'],
                'les gadjis'                                    : ['0,4,1,9'],
                'Les Héroïnes'                                  : ['0,3,10'],
                'Les Mains Gauches-festival queer féministe'    : ['0,4,12,0'],
                'Les Mains Libres'                              : ['1,1,7,5,2,1', '2,9,2,1'],
                'Le Snack'                                      : ['0,8,0'],
                'Le Social Brûle 13'                            : ['0,4,4,5', '1,0,2,0,3', '1,0,2,1,0,5'],
                'Les Ondines'                                   : ['1,1,7,5,1,0', '2,9,1,0'],
                'Les Paniers Marseillais'                       : ['1,1,7,2,0'],
                'Le Spot-Longchamp'                             : ['0,11,0'],
                'LES RASCASSES'                                 : ['1,1,2,0,1,0'],
                'Les régisseurs.ses sociaux'                    : ['0,0,2,4'],
                LESTOCKK                                        : ['0,1,5'],
                'Le Talus'                                      : ['0,7,7', '1,1,7,0,2'],
                'Le Vortex'                                     : ['2,0,0'],
                LFI13                                           : ['1,0,2,2,2'],
                'LGBT+ PACA'                                    : ['2,1,11'],
                "L'Histoire de l'oeil"                          : ['0,3,9'],
                "L'Hydre aux milles têtes"                      : ['0,3,6'],
                'Librairies | Bibliothèques'                    : ['0,3'],
                "Lieu d'Échanges et d'Ouvertures LEO"           : ['1,1,0,3,4,0', '1,1,3,3,2,0'],
                'Lieu de répit'                                 : ['0,0,2,0'],
                'Lieu de Répit'                                 : ['0,9,0'],
                Lieux                                           : ['0'],
                'Lieux de Répit'                                : ['0,9'],
                'Lieux Ruraux Autogérés'                        : ['0,5'],
                'Lieux Urbains Autogérés'                       : ['0,4'],
                'LiveGap Charts'                                : ['2,12,1,0,0'],
                Logement                                        : ['1,1,6,1', '1,1,8'],
                'Longo Maï'                                     : ['0,6,1', '0,7,0'],
                Lounapo                                         : ['0,0,8', '1,1,2,5,0'],
                'Luttes Locales'                                : ['1,1,4'],
                Macropolitique                                  : ['1,0'],
                'maladroite boxe populaire'                     : ['0,4,1,4'],
                'Maladroite BoxPop'                             : ['1,1,5,2,0'],
                Manifesten                                      : ['0,3,7', '0,4,0'],
                Manifestin                                      : ['0,4,0,0', '1,1,7,1,3'],
                Manoeuvre                                       : ['1,1,2,3,0,0'],
                'Marché des artisans et des producteurs bio'    : ['1,1,7,5,3,1', '2,9,3,1'],
                'Marchés'                                       : ['1,1,7,5,3', '2,9,3'],
                Marsactu                                        : ['1,0,0,0,1'],
                'Marseille vs Darmanin'                         : ['1,0,2,0,1'],
                'Mars Infos Autonomes'                          : ['2,0,2', '2,2,2'],
                Marsmut                                         : ['1,1,6,0,0'],
                Marsnet                                         : ['1,1,6,4,0'],
                MARSS                                           : ['0,4,0,1', '1,1,0,3,0', '1,1,3,3,0', '1,1,4,2'],
                'Masculinités'                                  : ['1,1,2,3,0'],
                'Mas de Granier (Saint Martin de Crau)'         : ['0,6,1,1', '0,7,0,1'],
                'Matériel/Outils'                               : ['1,1,6,0'],
                'Médias'                                        : ['2,2'],
                'Médiation | Gestion de Conflits'               : ['1,1,1'],
                'Mémoire des Sexualités'                        : ['0,3,8', '0,4,14'],
                Micropolitique                                  : ['1,1'],
                'Mille Bâbords'                                 : ['0,3,3', '0,4,3', '2,0,3', '2,2,3'],
                Mobilizon                                       : ['2,0,8'],
                'Modules COFOR'                                 : ['1,1,2,4,2'],
                'Monnaies Locales'                              : ['1,0,3,1'],
                'Mot de Passe (Français)'                       : ['1,1,3,2,0'],
                'Mouche Soldat Noir'                            : ['2,13,0,0,1'],
                Musique                                         : ['1,1,2,0,1'],
                Mutualisation                                   : ['1,1,6'],
                'Mutuelle MTPGB'                                : ['1,1,6,2,0'],
                Navigation                                      : ['1,1,2,5'],
                'Nouvelle Aube'                                 : ['0,0,3', '1,1,0,3,3', '1,1,0,4,0', '1,1,4,4'],
                NPA13                                           : ['1,0,2,2,1'],
                'Numérique'                                     : ['1,1,6,4'],
                'Numériques'                                    : ['2,12,1'],
                NUPES13                                         : ['1,0,2,2,0'],
                ODAMARS                                         : ['0,0,2,1'],
                Outils                                          : ['2,12'],
                Palama                                          : ['0,10,0'],
                'Partis Politiques'                             : ['1,0,2,2'],
                'Paul B. Preciado, trans-philosophe'            : ['2,4,0,4'],
                'Paul B. Preciado : trans révolutionnaire'      : ['2,4,0,5'],
                Peinture                                        : ['1,1,2,0,3'],
                'Permanence Juridique'                          : ['0,4,5,0', '1,1,3,0,1,0'],
                'Planning Familial 13'                          : ['1,1,0,3,5', '1,1,3,3,7'],
                Podcasts                                        : ['2,4'],
                Politique                                       : ['1,1,2,2'],
                'Pompe à bélier'                                : ['2,13,0,0,4'],
                'Pompes à eau'                                  : ['2,13,0,4'],
                'Porn on Mars festival de porn queer'           : ['1,1,2,0,2,0'],
                'Pour une Thérapie Transversale'                : ['1,1,0,0,0'],
                'Près de Chez Nous'                             : ['2,1,9'],
                Primitivi                                       : ['2,2,0'],
                'Prix libre pour affiches/flyers/micro-éditions': ['0,4,10,1'],
                'Production en ville'                           : ['1,1,7,0'],
                'Projet ASSAB'                                  : ['2,8,3'],
                Psychologie                                     : ['1,1,2,4'],
                'Psycho-Social'                                 : ['1,1,0,3', '1,1,3,3'],
                'Psychothérapie Institutionnelle'               : ['2,4,1'],
                PsySafe                                         : ['2,7,0,1'],
                'QX1 - WelcomeMap'                              : ['1,1,3,0,3', '2,1,2'],
                'Radar Squat'                                   : ['2,0,6'],
                'Radio BAM'                                     : ['2,3,3'],
                'Radio Galère (88.4)'                           : ['2,3,0'],
                'Radio Gazelle (98.0)'                          : ['2,3,2'],
                'Radio Grenouille (88.8)'                       : ['2,3,1'],
                Radios                                          : ['2,3'],
                'Radio Zinzine (Aix)'                           : ['2,3,5'],
                'Radio Zinzine (Limans)'                        : ['2,3,4'],
                "Rallumeurd'étoiles"                            : ['0,1,6'],
                Ramina                                          : ['1,1,3,0,0'],
                'Refuge | Hebergement'                          : ['0,10'],
                Regain                                          : ['1,1,8,0'],
                'Regain & Habitat Participatif Fr'              : ['2,1,14'],
                'Relève'                                        : ['2,11,0'],
                'Remèdes,Potions,Médocs'                        : ['1,1,0,1'],
                'Répertoires'                                   : ['2,7'],
                'Réseau COFOR'                                  : ['2,8,2'],
                'Réseau des Créfad'                             : ['2,8,7'],
                'Réseau des Paniers Marseillais - AMAP'         : ['2,1,0'],
                'Réseau Gestion_Mars_Conflits'                  : ['1,1,1,1', '2,8,4'],
                'Réseau Les Paniers Marseillais (AMAP)'         : ['2,8,1'],
                'Réseaux'                                       : ['2,8'],
                'Réseaux PDC'                                   : ['1,1,7,2'],
                'Résistance Aggression Publicitaire'            : ['0,4,2,4'],
                Ressources                                      : ['2'],
                Restos                                          : ['1,1,7,5,1', '2,9,1'],
                'Retab.fr'                                      : ['2,1,4'],
                'Rétablissement'                                : ['1,1,2,4,2,2'],
                Revenus                                         : ['1,1,6,2'],
                'Rev.Permanente13'                              : ['1,0,2,2,3'],
                'Revues | Journaux'                             : ['2,5'],
                RezoProspec                                     : ['2,8,0'],
                'Riposte Alimentaire'                           : ['1,0,9,0'],
                'Riposte Antifa'                                : ['1,0,5,1'],
                "Saccage (Anti-JO's)"                           : ['0,4,0,4'],
                "Sang D'encre"                                  : ['2,5,1'],
                'Santé'                                         : ['0,11'],
                'Santé | Soin | Thérapie'                       : ['1,1,0', '2,7,0'],
                'Savoir-faire'                                  : ['2,13'],
                'S.d.l.Terre'                                   : ['1,0,1,1'],
                'Sélection Rhizome'                             : ['2,13,0,0'],
                'SESL - solidaires étudiants'                   : ['0,4,4,0', '1,0,2,1,0,0'],
                'Sexualité'                                     : ['1,1,0,2'],
                Sindiane                                        : ['0,0,2,2'],
                sirakadjan                                      : ['0,4,1,11'],
                SoinSoin                                        : ['2,5,0'],
                Solidaires13                                    : ['0,4,4', '1,0,2,1,0'],
                'Solidaires MNA'                                : ['1,1,3,0,2'],
                'Solidarité | Entraide'                         : ['1,1,3'],
                'Solidarité Femmes 13'                          : ['0,0,7', '1,1,0,3,8', '1,1,3,3,5'],
                'Solidarité Réhab.'                             : ['0,0,6'],
                Spaam                                           : ['1,1,5,1'],
                SPAAM                                           : ['0,4,0,3', '2,6,0'],
                Squats                                          : ['0,8'],
                'sud éduc'                                      : ['0,4,4,3', '1,0,2,1,0,3'],
                'Super Cafoutch'                                : ['1,1,7,5,4,0', '2,9,4,0'],
                'Supermarchés'                                  : ['1,1,7,5,4', '2,9,4'],
                'Survie - PACA'                                 : ['1,0,4,0'],
                Syndicats                                       : ['1,0,2,1'],
                'Systèmes Agricoles'                            : ['2,13,0,3'],
                'Tango Queer'                                   : ['0,4,13,2'],
                Technopolice                                    : ['0,4,0,6', '1,0,8,0'],
                'Télé Mouche'                                   : ['0,4,2,7', '2,2,1'],
                'Terre de Liens'                                : ['2,1,10'],
                'Testing children node'                         : ['0,4,2,0,0,0'],
                'Thêatre'                                       : ['1,1,2,0,0'],
                'Thêatre Autogéré DAR'                          : ['1,1,2,0,0,0'],
                "Thêatre de l'Opprimé·e LABASE"                 : ['1,1,2,0,0,1'],
                TheNobelKitchen                                 : ['1,1,7,1,1'],
                'Thérapie'                                      : ['1,1,0,0'],
                'Thérapie Transversale'                         : ['1,2,1'],
                Transat                                         : ['0,4,2,1', '1,1,0,3,10', '1,1,3,3,8'],
                'Transféminisme*'                               : ['2,4,0'],
                TransFriendly                                   : ['2,7,0,0'],
                Transiscope                                     : ['2,1,8'],
                Transit                                         : ['0,3,5'],
                'Treynas (Ardèche)'                             : ['0,6,1,4', '0,7,0,4'],
                TRUC                                            : ['2,8,6'],
                UEEH                                            : ['1,1,2,2,1', '1,1,2,3,1'],
                'Un Autre Monde'                                : ['2,5,3'],
                'UNEF-13'                                       : ['1,0,2,1,2'],
                'Un monstre qui vous parle'                     : ['2,4,0,3'],
                'Un podcast à soi'                              : ['2,4,0,0'],
                'Urgence Palestine'                             : ['1,0,2,0,4', '1,0,4,1', '1,0,5,0'],
                'Vélo Sapiens'                                  : ['2,10,0,0'],
                'Videodrome 2'                                  : ['0,4,12'],
                Visualisation                                   : ['2,12,1,0'],
                'Vivre Avec'                                    : ['1,1,2,4,2,1'],
                Wildproject                                     : ['0,3,2'],
                'XR - Extinction Rebellion'                     : ['0,4,2,2'],
                'Youth for Climate'                             : ['0,4,2,3'],
                Zads                                            : ['1,0,1,0'],
                'Zone à Patates'                                : ['1,0,1,0,0']
            },
            hierarchy_tree_data             : {
                "name"    : "Rhizome Marseille",
                "free"    : true,
                "children": [

                    {
                        "name"    : "Lieux",
                        "free"    : true,
                        "children": [
                            {
                                "name"    : "Associations",
                                "free"    : true,
                                "children": [


                                    {
                                        "name": "Cité des Associations",
                                        "free": true,
                                    },
                                    {
                                        "name": "Cité de l'Agriculture",
                                        "free": true,
                                    },
                                    {
                                        "name"    : "JUST",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Lieu de répit",
                                                "free": true,
                                            },
                                            {
                                                "name": "ODAMARS",
                                                "free": true,
                                            },
                                            {
                                                "name": "Sindiane",
                                                "free": true,
                                            },
                                            {
                                                "name": "En passant par les calanques",
                                                "free": true,
                                            },
                                            {
                                                "name": "Les régisseurs.ses sociaux",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name": "Nouvelle Aube",
                                        "free": true,

                                    },
                                    {
                                        "name": "Autres Regards",
                                        "free": true,
                                    },
                                    {
                                        "name": "AIDES à Marseille",
                                        "free": true,
                                    },
                                    {
                                        "name": "Solidarité Réhab.",
                                        "free": true,
                                    },
                                    {
                                        "name": "Solidarité Femmes 13",
                                        "free": true,
                                    },
                                    {
                                        "name": "Lounapo",
                                        "free": true,
                                    },
                                    {
                                        "name": "Crefada",
                                        "free": true,
                                    },


                                ]
                            },
                            {
                                "name"    : "Bars | Cafés | Salles",
                                "free"    : true,
                                "children": [
                                    {
                                        "name": "Ciné Bar (ClubCoop)",
                                        "free": true,
                                    },
                                    {
                                        "name"    : "La Merveilleuse",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Jam Poèsie·Musique",
                                                "free": true,
                                            },
                                        ]

                                    },
                                    {
                                        "name": "El Aché de Cuba",
                                        "free": true,
                                    },
                                    {
                                        "name": "L'Embobineuse",
                                        "free": true,
                                    },
                                    {
                                        "name": "DATA",
                                        "free": true,
                                    },
                                    {
                                        "name": "LESTOCKK",
                                        "free": true,
                                    },
                                    {
                                        "name": "Rallumeurd'étoiles",
                                        "free": true,
                                    },

                                ]
                            },
                            {
                                "name"    : "Entreprises Autogérés",
                                "free"    : true,
                                "children": [
                                    {
                                        "name": "L'Après M",
                                        "free": true,
                                    },
                                    {
                                        "name": "L'Épicerie Paysanne",
                                        "free": true,
                                    },
                                    {
                                        "name": "Association Cuve",
                                        "free": true,
                                    },
                                    {
                                        "name": "Le Plan de A à Z",
                                        "free": true,
                                    },
                                    {
                                        "name": "Les Champignons de Marseille (?)",
                                        "free": true,
                                    },
                                    {
                                        "name": "1336 - Coop. production de thé ",
                                        "free": true,
                                    },

                                ]

                            },
                            {
                                "name"    : "Librairies | Bibliothèques",
                                "free"    : true,
                                "children": [
                                    {
                                        "name": "Alcazar",
                                        "free": true,
                                    },
                                    {
                                        "name": "Cité de l'Agriculture",
                                        "free": true,
                                    },
                                    {
                                        "name": "Wildproject",
                                        "free": true,
                                    },
                                    {
                                        "name": "Mille Bâbords",
                                        "free": true,

                                    },
                                    {
                                        "name": "La DAR",
                                        "free": true,
                                    },
                                    {
                                        "name": "Transit",
                                        "free": true,
                                    },
                                    {
                                        "name": "L'Hydre aux milles têtes",
                                        "free": true,
                                    },
                                    {
                                        "name": "Manifesten",
                                        "free": true,
                                    },
                                    {
                                        "name": "Mémoire des Sexualités",
                                        "free": true,

                                    },
                                    {
                                        "name": "L'Histoire de l'oeil",
                                        "free": true,
                                    },
                                    {
                                        "name": "Les Héroïnes",
                                        "free": true,
                                    },
                                    {
                                        "name": "CIRA",
                                        "free": true,
                                    },
                                    {
                                        "name": "DATA",
                                        "free": true,
                                    },

                                ]
                            },
                            {
                                "name"    : "Lieux Urbains Autogérés",
                                "free"    : true,
                                "children": [
                                    {
                                        "name"    : "Manifesten",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Manifestin",
                                                "free": true,
                                            },
                                            {
                                                "name": "MARSS",
                                                "free": true,
                                            },
                                            {
                                                "name": "Cercle de Nageurs en Eaux Troubles",
                                                "free": true,
                                            },
                                            {
                                                "name": "SPAAM",
                                                "free": true,
                                            },
                                            {
                                                "name": "Saccage (Anti-JO's)",
                                                "free": true
                                            },
                                            {
                                                "name": "Groupe Anti-gentrification",
                                                "free": true
                                            },
                                            {
                                                "name": "Technopolice",
                                                "free": true
                                            },
                                            {
                                                "name": "CQFD",
                                                "free": true
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "La DAR",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Le Café des Chômheureuses",
                                                "free": true,
                                            },
                                            {
                                                "name": "Atelier du Mouvement (danse)",
                                                "free": true,
                                            },
                                            {
                                                "name": "Atelier Théâtre",
                                                "free": true,
                                            },
                                            {
                                                "name": "accueil de jour Sirakadjan",
                                                "free": true,
                                            },
                                            {
                                                "name": "maladroite boxe populaire",
                                                "free": true,
                                            },
                                            {
                                                "name": "Atelier de Couture",
                                                "free": true,
                                            },
                                            {
                                                "name": "Bibliothèque Nulle part Ailleurs",
                                                "free": true,
                                            },
                                            {
                                                "name": "Cantine The Noble Kitchen",
                                                "free": true,
                                            },
                                            {
                                                "name": "Cantine Chaubouillante",
                                                "free": true,
                                            },
                                            {
                                                "name": "les gadjis",
                                                "free": true,
                                            },
                                            {
                                                "name": "antifa social club",
                                                "free": true
                                            },
                                            {
                                                "name": "sirakadjan",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "La Base",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name"    : "Alternatiba",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name"    : "ANV COP-21m",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Testing children node",
                                                                "free": true,
                                                            },

                                                        ]

                                                    },


                                                ]
                                            },
                                            {
                                                "name": "Transat",
                                                "free": true,
                                            },
                                            {
                                                "name": "XR - Extinction Rebellion",
                                                "free": true
                                            },
                                            {
                                                "name": "Youth for Climate",
                                                "free": true,
                                            },
                                            {
                                                "name": "Résistance Aggression Publicitaire",
                                                "free": true,
                                            },
                                            {
                                                "name": "Aïolibre",
                                                "free": true,
                                            },
                                            {
                                                "name": "La Roue (monnaie)",
                                                "free": true,
                                            },
                                            {
                                                "name": "Télé Mouche",
                                                "free": true,
                                            },
                                        ]
                                    },
                                    {
                                        "name"    : "Mille Bâbords",
                                        "free"    : true,
                                        "children": [

                                            {
                                                "name": "AG InterPro",
                                                "free": true,
                                            },
                                            {
                                                "name": "CNT13",
                                                "free": true,
                                            },

                                        ]

                                    },
                                    {
                                        "name"    : "Solidaires13",
                                        "free"    : true,
                                        "children": [

                                            {
                                                "name": "SESL - solidaires étudiants",
                                                "free": true,
                                            },
                                            {
                                                "name": "BTP autonome",
                                                "free": true,
                                            },
                                            {
                                                "name": "AG précaires",
                                                "free": true,
                                            },
                                            {
                                                "name": "sud éduc",
                                                "free": true,
                                            },
                                            {
                                                "name": "Asso",
                                                "free": true,
                                            },
                                            {
                                                "name": "Le Social Brûle 13",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Al Manba",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Permanence Juridique",
                                                "free": true,
                                            },
                                            {
                                                "name": "Cours FLE",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },
                                        ]
                                    },
                                    {
                                        "name"    : "La Nebula",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },

                                        ]

                                    },

                                    {
                                        "name"    : "Bourse du Travail",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "CGT chômeur précaires",
                                                "free": true,
                                            },
                                            {
                                                "name": "CGT spectacle",
                                                "free": true,
                                            },

                                        ]

                                    },
                                    {
                                        "name"    : "Centre LGBTQIA+",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "G.L.A.M",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Les 8 Pillards",
                                        "free"    : true,
                                        "children": [

                                            {
                                                "name": "",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },


                                        ]


                                    },
                                    {
                                        "name"    : "Imprimerie Partagée",
                                        "free"    : true,
                                        "children": [

                                            {
                                                "name": "Gratuit pour les urgences administratives",
                                                "free": true,
                                            },
                                            {
                                                "name": "Prix libre pour affiches/flyers/micro-éditions",
                                                "free": true,
                                            },


                                        ]


                                    },
                                    {
                                        "name"    : "CIRA",
                                        "free"    : true,
                                        "children": [

                                            {
                                                "name": "Causeries Mensueles",
                                                "free": true,
                                            },
                                            {
                                                "name": "Atelier Recherche Formation",
                                                "free": true,
                                            },


                                        ]


                                    },
                                    {
                                        "name"    : "Videodrome 2",
                                        "free"    : true,
                                        "children": [

                                            {
                                                "name": "Les Mains Gauches-festival queer féministe",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },


                                        ]

                                    },
                                    {
                                        "name"    : "Le Morozoff",
                                        "free"    : true,
                                        "children": [

                                            {
                                                "name": "Box",
                                                "free": true,
                                            },
                                            {
                                                "name": "Clownozoff",
                                                "free": true,
                                            },
                                            {
                                                "name": "Tango Queer",
                                                "free": true,
                                            },


                                        ]

                                    },
                                    {
                                        "name"    : "Mémoire des Sexualités",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Genre de Lutte",
                                                "free": true,
                                            },

                                        ]

                                    },
                                    {
                                        "name"    : "La Déviation",
                                        "free"    : true,
                                        "children": [

                                            {
                                                "name": "",
                                                "free": true,
                                            },
                                        ]


                                    },


                                ]


                            },

                            {
                                "name"    : "Lieux Ruraux Autogérés",
                                "free"    : true,
                                "children": [
                                    {
                                        "name": "Café Villageois (Lauris)",
                                        "free": true,
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },

                                ]
                            },
                            {
                                "name"    : "Laboratoires de vie",
                                "free"    : true,
                                "children": [
                                    {
                                        "name": "L'école des vivants",
                                        "free": true,
                                    },
                                    {
                                        "name"    : "Longo Maï",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Grange neuve (Forcarlquier)",
                                                "free": true,
                                            },
                                            {
                                                "name": "Mas de Granier (Saint Martin de Crau)",
                                                "free": true,
                                            },
                                            {
                                                "name": "La Cabrery (Luberon)",
                                                "free": true,
                                            },
                                            {
                                                "name": "Filature de Chantemerle (Hautes-Alpes)",
                                                "free": true,
                                            },
                                            {
                                                "name": "Treynas (Ardèche)",
                                                "free": true,
                                            },

                                        ]
                                    },

                                ]

                            },
                            {
                                "name"    : "Fermes",
                                "free"    : true,
                                "children": [

                                    {
                                        "name"    : "Longo Maï",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Grange neuve (Forcarlquier)",
                                                "free": true,
                                            },
                                            {
                                                "name": "Mas de Granier (Saint Martin de Crau)",
                                                "free": true,
                                            },
                                            {
                                                "name": "La Cabrery (Luberon)",
                                                "free": true,
                                            },
                                            {
                                                "name": "Filature de Chantemerle (Hautes-Alpes)",
                                                "free": true,
                                            },
                                            {
                                                "name": "Treynas (Ardèche)",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name": "La Caillasse (Cucuron)",
                                        "free": true,
                                    },
                                    {
                                        "name": "La ferme Capri",
                                        "free": true,
                                    },
                                    {
                                        "name": "Le Collet des Comtes",
                                        "free": true,
                                    },
                                    {
                                        "name": "Ferme du Roy d'Espagne",
                                        "free": true,
                                    },
                                    {
                                        "name": "Ferme Pastière (Meryrargues)",
                                        "free": true,
                                    },
                                    {
                                        "name": "Ferme de l'Etoile",
                                        "free": true,
                                    },
                                    {
                                        "name": "Le Talus",
                                        "free": true,
                                    },
                                    {
                                        "name": "La Bastide à Fruits",
                                        "free": true,
                                    },


                                ]
                            },


                            {
                                "name"    : "Squats",
                                "free"    : true,
                                "children": [
                                    {
                                        "name"    : "Le Snack",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "GSN Géstion de Conflits",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },
                                        ]
                                    },
                                    {
                                        "name": "La Tarantula",
                                        "free": true,
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },

                                ]
                            },
                            {
                                "name"    : "Lieux de Répit",
                                "free"    : true,
                                "children": [
                                    {
                                        "name": "Lieu de Répit",
                                        "free": true,
                                    },

                                ]

                            },


                            {
                                "name"    : "Refuge | Hebergement",
                                "free"    : true,
                                "children": [
                                    {
                                        "name": "Palama",
                                        "free": "true",
                                    },

                                ]
                            },
                            {
                                "name"    : "Santé",
                                "free"    : true,
                                "children": [
                                    {
                                        "name": "Le Spot-Longchamp",
                                        "free": "true",
                                    },
                                    {
                                        "name": "LE CHÂTEAU EN SANTÉ",
                                        "free": true,
                                    },

                                ]
                            }


                        ]
                    },
                    {
                        "name"    : "Catégories",
                        "free"    : true,
                        "children": [
                            {
                                "name"    : "Macropolitique",
                                "free"    : true,
                                "children": [
                                    {
                                        "name"    : "Bataille Médiatique",
                                        "free"    : true,
                                        "children": [

                                            {
                                                "name"    : "Journaux Indépendants",
                                                "free"    : true,
                                                "children": [

                                                    {
                                                        "name": "CQFD",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Marsactu",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "La terre en Thiers",
                                                        "free": true,
                                                    },

                                                ]
                                            },
                                            {
                                                "name": "Chaines Youtube",
                                                "free": true,
                                            },
                                            {
                                                "name": "Blogs",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Blocage",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name"    : "Zads",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "Zone à Patates",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                ]
                                            },
                                            {
                                                "name"    : "S.d.l.Terre",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "Comité Local 13",
                                                        "free": true,
                                                    },

                                                ]
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Face aux lois/politiques Gouv.",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name"    : "Autonomes",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "AG-InterPro",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Marseille vs Darmanin",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "CoMob-StCharles",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Le Social Brûle 13",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Urgence Palestine",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                ]
                                            },
                                            {
                                                "name"    : "Syndicats",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name"    : "Solidaires13",
                                                        "free"    : true,
                                                        "children": [

                                                            {
                                                                "name": "SESL - solidaires étudiants",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "BTP autonome",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "AG précaires",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "sud éduc",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Asso",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Le Social Brûle 13",
                                                                "free": true,
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        "name": "CNT13",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "UNEF-13",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Confédération Paysanne PACA",
                                                        "free": true,
                                                    },
                                                ]
                                            },
                                            {
                                                "name"    : "Partis Politiques",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "NUPES13",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "NPA13",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "LFI13",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Rev.Permanente13",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                ]
                                            },


                                        ]
                                    },
                                    {
                                        "name"    : "Face au système Financier",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name"    : "Banques Alternatives",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "La NEF",
                                                        "free": true,
                                                    },


                                                ]
                                            },
                                            {
                                                "name"    : "Monnaies Locales",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "La Roue (monnaie)",
                                                        "free": true,
                                                    },


                                                ]
                                            },
                                            {
                                                "name"    : "Cryptomonnaies",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "La Ĝ (June)",
                                                        "free": true,
                                                    },

                                                ]

                                            },


                                        ]
                                    },
                                    {
                                        "name"    : "Face au Néo-colonialisme",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Survie - PACA",
                                                "free": true,
                                            },
                                            {
                                                "name": "Urgence Palestine",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Face au Fascisme",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Urgence Palestine",
                                                "free": true,
                                            },
                                            {
                                                "name": "Riposte Antifa",
                                                "free": true,
                                            },
                                            {
                                                "name": "Brigade Antifa",
                                                "free": true,
                                            },
                                            {
                                                "name": "Colletif Antifa",
                                                "free": true,
                                            },
                                            {
                                                "name": "Antifa Social Club",
                                                "free": true,
                                            },
                                            {
                                                "name": "Assemblées Antifa",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Face à la Psychiatrie",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Fondation ERIE",
                                                "free": true,
                                            },
                                            {
                                                "name": "AiLSi",
                                                "free": true,
                                            },

                                        ]
                                    },

                                    {
                                        "name"    : " Face à la Gentrification",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Collectif Anti-Gentrification",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Face à la Surveillance",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Technopolice",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "F. au Système Alimentaire",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Riposte Alimentaire",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Internationnalisme",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "CIMK",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },

                                        ]
                                    },


                                ]
                            },


                            {
                                "name"    : "Micropolitique",
                                "free"    : true,
                                "children": [


                                    {
                                        "name"    : "Santé | Soin | Thérapie",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name"    : "Thérapie",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "Pour une Thérapie Transversale",
                                                        "free": true,

                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,

                                                    },

                                                ]
                                            },
                                            {
                                                "name"    : "Remèdes,Potions,Médocs",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "",
                                                        "free": true,

                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,

                                                    },

                                                ]

                                            },
                                            {
                                                "name"    : "Sexualité",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name"    : "Contraception",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "13ticules",
                                                                "free": true,

                                                            },


                                                        ]

                                                    },

                                                ]
                                            },
                                            {
                                                "name"    : "Psycho-Social",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "MARSS",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "COFOR",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "JUST",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Nouvelle Aube",
                                                        "free": true,

                                                    },
                                                    {
                                                        "name"    : "GEMs",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Lieu d'Échanges et d'Ouvertures LEO",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "CLUB PARENTHESE",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "",
                                                                "free": true,
                                                            },

                                                        ]

                                                    },
                                                    {
                                                        "name": "Planning Familial 13",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "IMAJSanté",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Autres Regards",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Solidarité Femmes 13",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Centre LGBTQIA+",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Transat",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "LE CHÂTEAU EN SANTÉ",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Equipe SIDIIS",
                                                        "free": true,
                                                    },


                                                ]
                                            },
                                            {
                                                "name"    : "Addictions",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "Nouvelle Aube",
                                                        "free": true,

                                                    },
                                                    {
                                                        "name": "Bus 31/32",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,

                                                    },

                                                ]
                                            },
                                            {
                                                "name"    : "",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "",
                                                        "free": true,

                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,

                                                    },

                                                ]
                                            },

                                        ]

                                    },

                                    {
                                        "name"    : "Médiation | Gestion de Conflits",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Collectif WD-40",
                                                "free": true,
                                            },
                                            {
                                                "name": "Réseau Gestion_Mars_Conflits",
                                                "free": true,

                                            },
                                            {
                                                "name": "AVAD",
                                                "free": true,

                                            },


                                        ]

                                    },

                                    {
                                        "name"    : "Éducation | Formation",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name"    : "Arts",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name"    : "Thêatre",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Thêatre Autogéré DAR",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Thêatre de l'Opprimé·e LABASE",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "",
                                                                "free": true,
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        "name"    : "Musique",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "LES RASCASSES",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "La lutte enchantée",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Atelier Palmas(€)",
                                                                "free": true,
                                                            },


                                                        ]
                                                    },
                                                    {
                                                        "name"    : "Cinéma",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Porn on Mars festival de porn queer",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "",
                                                                "free": true,
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        "name": "Peinture",
                                                        "free": true,
                                                    },
                                                ]
                                            },
                                            {
                                                "name"    : "Artisanat",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name"    : "Couture",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Atelier Couture (DAR)",
                                                                "free": true,
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                ]
                                            },
                                            {
                                                "name"    : "Politique",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "A2C-AutonomieDeClasse",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "UEEH",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                ]
                                            },
                                            {
                                                "name"    : "Genre & Sexualités",
                                                "free"    : true,
                                                "children": [

                                                    {
                                                        "name"    : "Masculinités",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Manoeuvre",
                                                                "free": true,

                                                            },
                                                        ]


                                                    },
                                                    {
                                                        "name": "UEEH",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Centre LGBTQIA+",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                ]
                                            },


                                            {
                                                "name"    : "Psychologie",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name"    : "Ateliers Nebula",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Gestion de Crises",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "",
                                                                "free": true,
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        "name"    : "Ateliers SPAAM",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Écoute Active",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Hypnose",
                                                                "free": true,
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        "name"    : "Modules COFOR",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Bien Être",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Vivre Avec",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Rétablissement",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Droit",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Addictions",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "",
                                                                "free": true,
                                                            },

                                                        ]
                                                    },

                                                ]
                                            },
                                            {
                                                "name"    : "Navigation",
                                                "free"    : true,
                                                "children": [

                                                    {
                                                        "name": "Lounapo",
                                                        "free": true,
                                                    },
                                                ]
                                            },
                                            {
                                                "name"    : "Autres",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "Crefada",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                ]
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Solidarité | Entraide",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name"    : "Acceuil Migrant·s·es",
                                                "free"    : true,
                                                "children": [

                                                    {
                                                        "name": "Ramina",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name"    : "Al Manba",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Permanence Juridique",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Cours FLE",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "",
                                                                "free": true,
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        "name": "Solidaires MNA",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "QX1 - WelcomeMap",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "G.L.A.M",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "AUP - Demandeurs d'asile",
                                                        "free": true,
                                                    },


                                                ]
                                            },
                                            {
                                                "name"    : "Administratif",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },

                                                ]
                                            },
                                            {
                                                "name"    : "Langues",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "Mot de Passe (Français)",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },

                                                ]

                                            },
                                            {
                                                "name"    : "Psycho-Social",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "MARSS",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "COFOR",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name"    : "GEMs",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Lieu d'Échanges et d'Ouvertures LEO",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "CLUB PARENTHESE",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "",
                                                                "free": true,
                                                            },

                                                        ]

                                                    },
                                                    {
                                                        "name": "IMAJSanté",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "H.A.S",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Solidarité Femmes 13",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Centre LGBTQIA+",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Planning Familial 13",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Transat",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Autres Regards",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "LE CHÂTEAU EN SANTÉ",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "AVAD",
                                                        "free": true,

                                                    },

                                                ]
                                            },
                                            {
                                                "name"    : "Chomage | Precarité",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "Chomheureuses",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Chomeurs Précaires 13",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },

                                                ]
                                            },
                                            {
                                                "name"    : "",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },

                                                ]
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Luttes Locales",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Cité de l'Agriculture",
                                                "free": true,
                                            },
                                            {
                                                "name": "CHO3",
                                                "free": true,
                                            },
                                            {
                                                "name": "MARSS",
                                                "free": true,
                                            },
                                            {
                                                "name": "JUST",
                                                "free": true,

                                            },
                                            {
                                                "name": "Nouvelle Aube",
                                                "free": true,

                                            },
                                            {
                                                "name": "La Roue (monnaie)",
                                                "free": true,
                                            },
                                            {
                                                "name": "Approches Cultures & Territoires",
                                                "free": true,
                                            },
                                            {
                                                "name": "AUP - Demandeurs d'asile",
                                                "free": true,
                                            },

                                            {
                                                "name": "",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Autodéfense",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Legal Team 13",
                                                "free": true,
                                            },
                                            {
                                                "name": "Spaam",
                                                "free": true,
                                            },
                                            {
                                                "name"    : "Arts Martiaux/Box",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "Maladroite BoxPop",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Box Morozoff",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,

                                                    },

                                                ]

                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Mutualisation",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name"    : "Matériel/Outils",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "Marsmut",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },

                                                ]
                                            },
                                            {
                                                "name": "Logement",
                                                "free": true,
                                            },
                                            {
                                                "name"    : "Revenus",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "Mutuelle MTPGB",
                                                        "free": true,
                                                    },

                                                ]
                                            },
                                            {
                                                "name": "Ateliers",
                                                "free": true,
                                            },
                                            {
                                                "name"    : "Numérique",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "Marsnet",
                                                        "free": true,
                                                    },

                                                ]
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Bouffe",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name"    : "Production en ville",
                                                "free"    : true,
                                                "children": [

                                                    {
                                                        "name": "Les Champi. de Marseille",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Association Cuve",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Le Talus",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "La ferme Capri",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },

                                                ]
                                            },
                                            {
                                                "name"    : "Cantines Solidaires",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "CHO3",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "TheNobelKitchen",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "La Marmite Joyeuse",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Manifestin",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Casa Consolat",
                                                        "free": true,
                                                    },
                                                ]
                                            },
                                            {
                                                "name"    : "Réseaux PDC",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "Les Paniers Marseillais",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Casa Consolat",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Le Marché Rouge",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },

                                                ]
                                            },
                                            {
                                                "name"    : "Gestion Déchets",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },

                                                ]
                                            },
                                            {
                                                "name"    : "Entreprises-Autog.",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "L'Après M",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "L'Épicerie Paysanne",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Association Cuve",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Le Plan de A à Z",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Les Champignons de Marseille",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "",
                                                        "free": true,
                                                    },

                                                ]
                                            },
                                            {
                                                "name"    : "Circuit Court",
                                                "free"    : true,
                                                "children": [

                                                    {
                                                        "name"    : "Épiceries",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Bar à Vrac",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "L'Épicerie Paysanne",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "La plaine fraicheur",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Adèle",
                                                                "free": true,
                                                            },

                                                            {
                                                                "name": "",
                                                                "free": true,
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        "name"    : "Restos",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Les Ondines",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Café l'Ecomotive",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Casa Consolat",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Le Grain de Sable",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "La Marmite Joyeuse",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "",
                                                                "free": true,
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        "name"    : "Boulangeries",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Le Bar à Pain",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Les Mains Libres",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Boulangerie-Café Pain Salvator",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "House of Pain",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "",
                                                                "free": true,
                                                            },


                                                        ]
                                                    },
                                                    {
                                                        "name"    : "Marchés",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Le Marché Rouge",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Marché des artisans et des producteurs bio",
                                                                "free": true,
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        "name"    : "Supermarchés",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Super Cafoutch",
                                                                "free": true,
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        "name"    : "Friperies",
                                                        "free"    : true,
                                                        "children": [
                                                            {
                                                                "name": "Frip'Insertion - Libération",
                                                                "free": true,
                                                            },
                                                            {
                                                                "name": "Frip'Insertion - Capelette ",
                                                                "free": true,
                                                            },

                                                        ]
                                                    },


                                                ]
                                            },

                                        ]

                                    },
                                    {
                                        "name"    : "Logement",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Regain",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },

                                        ]
                                    },

                                ]
                            },
                            {
                                "name"    : "Infra-personnel",
                                "free"    : true,
                                "children": [


                                    {
                                        "name": "Dispositifs Travail Singularité",
                                        "free": true,
                                    },

                                    {
                                        "name": "Thérapie Transversale",
                                        "free": true,

                                    },

                                    {
                                        "name": "",
                                        "free": true,
                                    },


                                ]
                            }


                        ]
                    },
                    {
                        "name"    : "Ressources",
                        "free"    : true,
                        "children": [

                            {
                                "name"    : "Agendas",
                                "free"    : true,
                                "children": [

                                    {
                                        "name": "Le Vortex",
                                        "free": true,
                                    },
                                    {
                                        "name": "Journal Ventilo",
                                        "free": true,
                                    },
                                    {
                                        "name": "Mars Infos Autonomes",
                                        "free": true,
                                    },
                                    {
                                        "name": "Mille Bâbords",
                                        "free": true,

                                    },
                                    {
                                        "name": "Centre LGBTQIA+",
                                        "free": true,
                                    },
                                    {
                                        "name": "Approches Cultures & Territoires",
                                        "free": true,
                                    },
                                    {
                                        "name": "Radar Squat",
                                        "free": true,
                                    },
                                    {
                                        "name": "Démosphère",
                                        "free": true,
                                    },
                                    {
                                        "name": "Mobilizon",
                                        "free": true,
                                    },


                                ]
                            },
                            {
                                "name"    : "Cartes",
                                "free"    : true,
                                "children": [
                                    {
                                        "name": "Réseau des Paniers Marseillais - AMAP",
                                        "free": true,
                                    },
                                    {
                                        "name": "Carte Autogéré Rhizome",
                                        "free": true,
                                    },
                                    {
                                        "name": "QX1 - WelcomeMap",
                                        "free": true,
                                    },
                                    {
                                        "name": "Carte des Luttes - Reporterre",
                                        "free": true,
                                    },
                                    {
                                        "name": "Retab.fr",
                                        "free": true,
                                    },
                                    {
                                        "name": "Carto-Marseille",
                                        "free": true,
                                    },
                                    {
                                        "name": "DICADD",
                                        "free": true,
                                    },
                                    {
                                        "name": "FransGenre",
                                        "free": true,
                                    },
                                    {
                                        "name": "Transiscope",
                                        "free": true,
                                    },
                                    {
                                        "name": "Près de Chez Nous",
                                        "free": true,
                                    },
                                    {
                                        "name": "Terre de Liens",
                                        "free": true,
                                    },
                                    {
                                        "name": "LGBT+ PACA",
                                        "free": true,
                                    },
                                    {
                                        "name": "Habicoop",
                                        "free": true,
                                    },
                                    {
                                        "name": "Hameaux Légers",
                                        "free": true,
                                    },
                                    {
                                        "name": "Regain & Habitat Participatif Fr",
                                        "free": true,
                                    },
                                    {
                                        "name": "Les Écotables",
                                        "free": true,
                                    },


                                ]
                            },
                            {
                                "name"    : "Médias",
                                "free"    : true,
                                "children": [
                                    {
                                        "name"    : "Primitivi",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name"    : "Ami·es",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "L'Empaille (Mouret (12))",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Association Kokopelli",
                                                        "free": true,
                                                    },

                                                ]
                                            },


                                        ]
                                    },
                                    {
                                        "name": "Télé Mouche",
                                        "free": true,
                                    },
                                    {
                                        "name": "Mars Infos Autonomes",
                                        "free": true,
                                    },
                                    {
                                        "name": "Mille Bâbords",
                                        "free": true,

                                    },
                                    {
                                        "name": "CQFD",
                                        "free": true,
                                    },
                                ]
                            },
                            {
                                "name"    : "Radios",
                                "free"    : true,
                                "children": [
                                    {
                                        "name": "Radio Galère (88.4)",
                                        "free": true,
                                    },
                                    {
                                        "name": "Radio Grenouille (88.8)",
                                        "free": true,
                                    },
                                    {
                                        "name": "Radio Gazelle (98.0)",
                                        "free": true,
                                    },
                                    {
                                        "name": "Radio BAM",
                                        "free": true,
                                    },
                                    {
                                        "name": "Radio Zinzine (Limans)",
                                        "free": true,
                                    },
                                    {
                                        "name": "Radio Zinzine (Aix)",
                                        "free": true,
                                    },
                                    {
                                        "name": "DATA",
                                        "free": true,
                                    },


                                ]
                            },
                            {
                                "name"    : "Podcasts",
                                "free"    : true,
                                "children": [
                                    {
                                        "name"    : "Transféminisme*",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Un podcast à soi",
                                                "free": true,
                                            },
                                            {
                                                "name": "Les Couilles sur la table",
                                                "free": true,
                                            },
                                            {
                                                "name": "Le Coeur sur la table",
                                                "free": true,
                                            },
                                            {
                                                "name": "Un monstre qui vous parle",
                                                "free": true,
                                            },
                                            {
                                                "name": "Paul B. Preciado, trans-philosophe",
                                                "free": true,
                                            },
                                            {
                                                "name": "Paul B. Preciado : trans révolutionnaire",
                                                "free": true,
                                            },


                                        ]
                                    },
                                    {
                                        "name"    : "Psychothérapie Institutionnelle",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "De Saint-Alban à La Borde - France Culture",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },
                                        ]
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },


                                ]
                            },
                            {
                                "name"    : "Revues | Journaux",
                                "free"    : true,
                                "children": [
                                    {
                                        "name": "SoinSoin",
                                        "free": true,
                                    },
                                    {
                                        "name": "Sang D'encre",
                                        "free": true,
                                    },
                                    {
                                        "name": "Les Cahiers A2C",
                                        "free": true,
                                    },
                                    {
                                        "name": "Un Autre Monde",
                                        "free": true,
                                    },
                                    {
                                        "name": "CQFD",
                                        "free": true,
                                    },
                                    {
                                        "name": "La terre en Thiers",
                                        "free": true,
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },


                                ]


                            },
                            {
                                "name"    : "InfoKiosques",
                                "free"    : true,
                                "children": [

                                    {
                                        "name": "SPAAM",
                                        "free": true,

                                    },
                                    {
                                        "name": "Folie et Politique -Barge",
                                        "free": true,
                                    },


                                ]
                            },
                            {
                                "name"    : "Répertoires",
                                "free"    : true,
                                "children": [
                                    {
                                        "name"    : "Santé | Soin | Thérapie",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "TransFriendly",
                                                "free": true,
                                            },
                                            {
                                                "name": "PsySafe",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name": "Juridique",
                                        "free": true,
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },

                                ]
                            },
                            {
                                "name"    : "Réseaux",
                                "free"    : true,
                                "children": [
                                    {
                                        "name": "RezoProspec",
                                        "free": true,
                                    },
                                    {
                                        "name": "Réseau Les Paniers Marseillais (AMAP)",
                                        "free": true,
                                    },
                                    {
                                        "name": "Réseau COFOR",
                                        "free": true,
                                    },
                                    {
                                        "name": "Projet ASSAB",
                                        "free": true,
                                    },
                                    {
                                        "name": "Réseau Gestion_Mars_Conflits",
                                        "free": true,

                                    },
                                    {
                                        "name": "Le Marché Rouge",
                                        "free": true,
                                    },
                                    {
                                        "name": "TRUC",
                                        "free": true,
                                    },
                                    {
                                        "name": "Réseau des Créfad",
                                        "free": true,
                                    },
                                    {
                                        "name": "Habicoop",
                                        "free": true,
                                    },

                                ]


                            },
                            {
                                "name"    : "Circuit Court",
                                "free"    : true,
                                "children": [

                                    {
                                        "name"    : "Épiceries",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Bar à Vrac",
                                                "free": true,
                                            },
                                            {
                                                "name": "L'Épicerie Paysanne",
                                                "free": true,
                                            },
                                            {
                                                "name": "La plaine fraicheur",
                                                "free": true,
                                            },
                                            {
                                                "name": "Adèle",
                                                "free": true,
                                            },

                                            {
                                                "name": "",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Restos",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Les Ondines",
                                                "free": true,
                                            },
                                            {
                                                "name": "Café l'Ecomotive",
                                                "free": true,
                                            },
                                            {
                                                "name": "Casa Consolat",
                                                "free": true,
                                            },
                                            {
                                                "name": "Le Grain de Sable",
                                                "free": true,
                                            },
                                            {
                                                "name": "La Marmite Joyeuse",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },
                                        ]
                                    },
                                    {
                                        "name"    : "Boulangeries",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Le Bar à Pain",
                                                "free": true,
                                            },
                                            {
                                                "name": "Les Mains Libres",
                                                "free": true,
                                            },
                                            {
                                                "name": "Boulangerie-Café Pain Salvator",
                                                "free": true,
                                            },
                                            {
                                                "name": "House of Pain",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                                "url" : "",
                                            },


                                        ]
                                    },
                                    {
                                        "name"    : "Marchés",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Le Marché Rouge",
                                                "free": true,
                                            },
                                            {
                                                "name": "Marché des artisans et des producteurs bio",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Supermarchés",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Super Cafoutch",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name"    : "Friperies",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Frip'Insertion - Libération",
                                                "free": true,
                                            },
                                            {
                                                "name": "Frip'Insertion - Capelette ",
                                                "free": true,
                                            },

                                        ]
                                    },


                                ]
                            },
                            {
                                "name"    : "Ateliers",
                                "free"    : true,
                                "children": [

                                    {
                                        "name"    : "Ateliers Vélo",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name": "Vélo Sapiens",
                                                "free": true,
                                            },
                                            {
                                                "name": "Collectif Vélos en Ville",
                                                "free": true,
                                            },
                                            {
                                                "name": "",
                                                "free": true,
                                            },

                                        ]
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },


                                ]
                            },
                            {
                                "name"    : "Festivals",
                                "free"    : true,
                                "children": [
                                    {
                                        "name": "Relève",
                                        "free": true,
                                    },
                                    {
                                        "name": "",
                                        "free": true,
                                    },

                                ]
                            },
                            {
                                "name"    : "Outils",
                                "free"    : true,
                                "children": [

                                    {
                                        "name": "Calculs Mutuelle",
                                        "free": true,
                                    },
                                    {
                                        "name"    : "Numériques",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name"    : "Visualisation",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "LiveGap Charts",
                                                        "free": true,

                                                    },
                                                    {
                                                        "name": "Graph Maker",
                                                        "free": true,

                                                    },
                                                ]
                                            },
                                        ]
                                    },

                                ]
                            },
                            {
                                "name"    : "Savoir-faire",
                                "free"    : true,
                                "children": [

                                    {
                                        "name"    : "Autonomie",
                                        "free"    : true,
                                        "children": [
                                            {
                                                "name"    : "Sélection Rhizome",
                                                "free"    : true,
                                                "children": [
                                                    {
                                                        "name": "Le moteur stirling",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Mouche Soldat Noir",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Aquaponie",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Biodigesteur",
                                                        "free": true,
                                                    },
                                                    {
                                                        "name": "Pompe à bélier",
                                                        "free": true,
                                                    },
                                                ]
                                            },
                                            {
                                                "name": "Énergie",
                                                "free": true,
                                            },
                                            {
                                                "name": "Compostage",
                                                "free": true,
                                            },
                                            {
                                                "name": "Systèmes Agricoles",
                                                "free": true,
                                            },
                                            {
                                                "name": "Pompes à eau",
                                                "free": true,
                                            },

                                        ]
                                    },

                                ]
                            }
                        ]
                    }
                ]
            }
        };
    }
}